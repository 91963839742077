import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import styled from "styled-components"

import Head from "../components/Head"
import { LogoIcon } from "../components/LogoIcon"
import { GlobalStyles, Hero } from "../styles"
import { GlobalNav } from "../components/GlobalNav"
import { PageContainer } from "../templates/Page.styles"

type FluidImage = {
  file: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

const Home = () => {
  const images = useStaticQuery(graphql`
    query MyQuery {
      allFile(
        filter: {
          relativePath: {
            in: [
              "iphone/day.png"
              "iphone/month.png"
              "iphone/year.png"
              "iphone/widget-lg.png"
              "iphone/widget-md.png"
              "iphone/widget-sm.png"
              "watch/watch.png"
              "watch/settings.png"
              "watch/complication.png"
            ]
          }
        }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 320, quality: 74) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  `)

  const {
    day,
    month,
    year,
    lg,
    md,
    sm,
    watch,
    settings,
    complication,
  } = images.allFile.nodes.reduce((acc: any, image: any) => {
    console.log(image.childImageSharp.fluid.src)
    if (image.childImageSharp.fluid.src.includes("day")) {
      acc.day = image
    }

    if (image.childImageSharp.fluid.src.includes("month")) {
      acc.month = image
    }

    if (image.childImageSharp.fluid.src.includes("year")) {
      acc.year = image
    }

    if (image.childImageSharp.fluid.src.includes("lg")) {
      acc.lg = image
    }

    if (image.childImageSharp.fluid.src.includes("md")) {
      acc.md = image
    }

    if (image.childImageSharp.fluid.src.includes("sm")) {
      acc.sm = image
    }

    if (image.childImageSharp.fluid.src.includes("watch")) {
      acc.watch = image
    }

    if (image.childImageSharp.fluid.src.includes("settings")) {
      acc.settings = image
    }

    if (image.childImageSharp.fluid.src.includes("complication")) {
      acc.complication = image
    }

    return acc
  }, {})

  return (
    <>
      <Head
        title="Sober Wagon - Sobriety Counter"
        description="App for iphone and Apple Watch to keep track of your sober days."
      />
      <GlobalStyles />
      <PageContainer>
        <GlobalNav />
        <main>
          <Hero>
            <h1>Sober Wagon</h1>
            <p>
              A simple and beautiful app for iPhone and Apple Watch for tracking
              your sober days.
            </p>
          </Hero>
          <OneColumn>
            <h2>Features</h2>
          </OneColumn>
          <OneColumn>
            <h3>Different views to track your time</h3>
            <p>View by day, month, or year.</p>
          </OneColumn>
          <ThreeColumn>
            <div>
              <Img fluid={day.childImageSharp.fluid} className="center" />
            </div>
            <div>
              <Img fluid={month.childImageSharp.fluid} className="center" />
            </div>
            <div>
              <Img fluid={year.childImageSharp.fluid} className="center" />
            </div>
          </ThreeColumn>
          <OneColumn>
            <h3>Widgets for a quick glace</h3>
            <p>Track your time without even launching the app.</p>
          </OneColumn>
          <ThreeColumn>
            <div>
              <Img fluid={sm.childImageSharp.fluid} className="center" />
            </div>
            <div>
              <Img fluid={md.childImageSharp.fluid} className="center" />
            </div>
            <div>
              <Img fluid={lg.childImageSharp.fluid} className="center" />
            </div>
          </ThreeColumn>
          <OneColumn>
            <h3>Syncs directly to your Apple Watch</h3>
            <p>
              If you have an Apple Watch paired with your phone, they'll stay in
              sync.
            </p>
          </OneColumn>
          <ThreeColumn>
            <div>
              <Img fluid={watch.childImageSharp.fluid} className="center" />
            </div>
            <div>
              <Img fluid={settings.childImageSharp.fluid} className="center" />
            </div>
            <div>
              <Img
                fluid={complication.childImageSharp.fluid}
                className="center"
              />
            </div>
          </ThreeColumn>
          <OneColumn id="support">
            <h2>Support</h2>
            <p>
              For questions, comments, bugs, and feature requests, please{" "}
              <a href="mailto:support@soberwagon.atlassian.net">contact us</a>.
            </p>
          </OneColumn>
          <OneColumn id="privacy">
            <h2>Privacy Policy</h2>
            <p>
              We never track or store your data outside of your own phone or
              watch. Data is only stored on device except to communicate with
              your watch directly (if applicable).
            </p>
          </OneColumn>
          <OneColumn>
            <footer>
              <LogoIcon />
              <p>&copy; {new Date().getFullYear()} Sober Wagon</p>
            </footer>
          </OneColumn>
        </main>
      </PageContainer>
    </>
  )
}

export default Home

const OneColumn = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 100%;
  max-width: 1200px;
  margin: 1rem auto;
  padding: 1rem;
`

const TwoColumn = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 100%;
  max-width: 1200px;
  margin: 1rem auto;
  padding: 1rem;
  @media (min-width: 768px) {
    grid-template-columns: 50% 50%;
  }
`

const ThreeColumn = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 100%;
  max-width: 1200px;
  margin: 1rem auto;
  padding: 1rem;
  @media (min-width: 768px) {
    grid-template-columns: 33% 33% 33%;
  }
`
