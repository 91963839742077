import React from "react"
import { Helmet } from "react-helmet"

export type HeadProps = {
  title: string
  description: string
}

const Head = ({ title, description }: HeadProps) => {
  return (
    <Helmet htmlAttributes={{ lang: "en" }}>
      <title>{`Sober Wagon | ${title}`}</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}

export default Head
